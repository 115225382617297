import request from "@/utils/request";

export function masterAccess(data) {
  return request({
    method: "POST",
    url: "access/get_all_group_role",
    data: data
  });
}

export function setAccess(data) {
  return request({
    method: "POST",
    url: "access/set_group_role",
    data: data
  });
}

export function getNisDefaultUsername() {
  return request({
    method: "POST",
    url: "student/get_nis_default_username"
  });
}

export function setNisDefaultUsername(data) {
  return request({
    method: "POST",
    url: "student/set_nis_default_username",
    data: data
  });
}
