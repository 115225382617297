<template>
  <div :class="darkmode || 'grey lighten-5'">
    <v-card class="pa-5 elevation-0">
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable"
        fixed-header
        height="400"
        disable-pagination
        hide-default-footer
        class="elevation-0"
      >
        <template #item.action="{ item }">
          <v-btn
            :loading="item.loading"
            icon
            dark
            class="primary"
            @click="getMasterAccess(item)"
          >
            <v-icon>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <Dialog
      :modal="modal"
      :requestBody="requestBody"
      :loading="loadingSave"
      :accessList="accessList"
      @close="modal = false"
      @save="save"
    />
  </div>
</template>

<script>
import { masterAccess, setAccess } from "@/api/admin/settings/user";

export default {
  components: {
    Dialog: () => import("./Dialog")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      modal: false,
      loadingSave: false,
      requestBody: {
        kodes: [],
        role: ""
      },
      accessList: [],
      dataTable: [
        { name: this.$i18n.t("app.student"), role: "student", loading: false }
        // { name: this.$i18n.t("ppdb.student.tab2"), role: "parent", loading: false }
      ],
      tableHeaders: [
        {
          text: this.$i18n.t("app.name"),
          value: "name"
        },
        {
          text: this.$i18n.t("app.action"),
          value: "action",
          width: 100
        }
      ]
    };
  },
  methods: {
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    resetBody() {
      this.accessList = [];
      this.requestBody = {
        kodes: [],
        role: ""
      };
    },
    async save() {
      this.loadingSave = true;
      const arrayKodes = this.accessList.map(access => access.kodes);
      this.requestBody.kodes = [].concat(...arrayKodes);
      try {
        const response = await setAccess(this.requestBody);
        if (response.data.code) {
          this.snackBar(false, response.data.message);
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        console.log("save()", error);
      }
      this.modal = false;
      this.loadingSave = false;
      this.resetBody();
    },
    async getMasterAccess(item) {
      item.loading = true;
      this.requestBody.role = item.role;
      try {
        const response = await masterAccess({ role: item.role });
        if (response.data.code) {
          const result = response.data.data;
          this.requestBody.kodes = result.kodes;
          this.accessList = result.access;
        }
      } catch (error) {
        console.log("getMasterAccess()", error);
      }
      item.loading = false;
      this.modal = true;
    }
  }
};
</script>
